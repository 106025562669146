import Vue from 'vue'
import Vuex from 'vuex'
import {Storage} from "@/Services/Storage";
import userModule from "./modules/user-module";
import orderModule from "./modules/orders-module";

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        loading: false,
        currentStore : null,
        stores: [
            {
                id: '021',
                location: 'Shekisheki',
            },
            {
                id: '001',
                location: 'Kafue',
            },
        ],
        isUpdateAvailable: false,
    },
    getters: {
        getUpdateAvailability: (state) => state.isUpdateAvailable,
        getLoadingState: (state) => state.loading,
        getAllStores: (state) => state.stores,
        getCurrentStore:(state) => state.currentStore
    },
    mutations: {
        setUpdateAvailability: (state, payload) => state.isUpdateAvailable = payload,
        setLoadingState: (state, payload) => state.loading = payload,
        setMobileUser: (state, payload) => Storage.setMobileUser(payload),

        setCurrentStore: async (state, payload) => {
            if (payload) await Storage.setCurrentStore(payload)
            else payload = await Storage.getCurrentStore();
            state.currentStore = payload;
        },
    },
    actions: {
        syncData: (context) => {
            return Promise.all([
                context.dispatch(`getAllUsers`),
            ]);
        },

        refreshState:(context)=>{
            return Promise.all([
               context.commit(`setAllUsers`),
               context.commit(`setCurrentStore`),
               context.commit(`setCurrentUser`),
            ]);
        }

    },
    modules: {userModule , orderModule}
})
