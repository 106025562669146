<template>
  <div class="page">
    <Header/>
    <div class="container d-flex justify-content-center">
      <UserLogin />
    </div>
    <p class="text-center mt-5">Powered By <a target="_blank" href="https://chrilantech.com">Chrilan Technology</a></p>
  </div>
</template>

<script>
import Header from "@/components/layout/utils/Header";
import UserLogin from "@/components/UserLogin";

export default {
  name: "UserLoginView",
  beforeMount() {
    this.syncData();
  },
  components: {UserLogin, Header},
  methods : {
    syncData(){
      this.$utils.showLoading()
      return this.$store.dispatch(`syncData`)
          .finally(()=>this.$utils.hideLoading());
    }
  }
}
</script>
