<template>
  <div>
    <loading :active.sync="loading"
             :can-cancel="false"
             :is-full-page="true">
      <div class="d-flex flex-column justify-content-center ">
        <img style="width : 150px;height: 150px" src="/favicon.ico" class="rotate"/>
        <br>
        <span class="fw-bolder text-center text-dark-emphasis text-uppercase">Please Wait...</span>
        <div id="wave">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>
    </loading>
  </div>
</template>
<script>
export default {
  name: 'Loader',
  computed: {
    loading() {
      return this.$store.getters.getLoadingState;
    },
  }
}
</script>
<style lang="scss" scoped>
.rotate {
  width: 100px;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

div#wave {
  position:relative;
  margin-top:5px;
  text-align:center;
  width:100px;
  height:100px;
  margin-left: auto;
  margin-right: auto;
  .dot {
    display:inline-block;
    width:12px;
    height:12px;
    border-radius:50%;
    margin-right:3px;
    background:#303131;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}
</style>