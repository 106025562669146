import {Storage} from "@/Services/Storage";
import axios from "axios";

export default {
    name: 'user',
    state: {
        allUsers : [],
        currentUser : {}
    },
    getters: {
        getAllUsers: (state) => state.allUsers,
        getCurrentUser : (state) => state.currentUser,
    },
    mutations: {
        setAllUsers: async (state, payload) => {
            if (payload) await Storage.setAllUsers(payload)
            else payload = await Storage.getAllUsers();
            state.allUsers = payload?payload : [];
        },

        setCurrentUser: async (state, payload) => {
            if (payload) await Storage.setCurrentUser(payload)
            else payload = await Storage.getCurrentUser();
            state.currentUser = payload?payload : {};
        },
    },
    actions: {
        getAllUsers: async (context) => {
            return axios.get(franchiseTomcatApi+`employee/l_and_a_logistics_l_and_a_logistics/getAll`)
                .then(({data})=>context.commit('setAllUsers',data))
                .catch(err=>errorHandler.tomcatError(err))
        },
        validateLogin : async (context,payload) =>{
            let users = await Storage.getAllUsers();
            let currentUser = users.find(user=>user.Password === payload.password);
            if (currentUser){
                await context.commit(`setCurrentStore`, payload.location)
                await context.commit(`setCurrentUser`,currentUser)
                return true;
            }
            return false;
        }
    }
}
