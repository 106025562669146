<template>
  <div>
    <div class="card pt-3" style="border:none">

      <div class="card-body p-1">
        <h4 class="card-title mb-3   text-dark text-uppercase" style="font-weight:700"><span
            style="color: #9ccc65">User</span> Login</h4>

        <form @submit.prevent="login">
          <div class="row">
            <div class="col">
              <div class="form-floating mb-3">
                <div class="form-floating">
                  <select class="form-select" v-model="selectedStore"
                          id="storeSelect" aria-label="Store label select example" required>
                    <option v-for="(store,n) in stores" :value="store">{{ store.location }}</option>
                  </select>
                  <label for="storeSelect">Select Store</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input :type="passwordType"  class="form-control"
                   v-model="password" id="password"
                   placeholder="Password" required>
            <label for="password">User Password</label>
          </div>
          <label class="show-password-checkbox mb-2">
            <input type="checkbox" v-model="showPassword">
            Show Password
          </label>

          <div class="text-center submit-btns">
            <button class="btn" >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "UserLogin",
  data() {
    return {
      showPassword : false,
      password: '',
      selectedStore : null
    }
  },
  computed: {
    passwordType () {
      return this.showPassword?`text`:`password`;
    },
    stores(){
      return this.$store.getters.getAllStores;
    },
  },
  methods: {
    login(){
      this.$utils.showLoading();
      let obj = {
        location : this.selectedStore,
        password : this.password
      }
      return this.$store.dispatch(`validateLogin` , obj)
          .then(res=>{
            if (!res) return responses.showWarning(`Incorrect Password`);
            return this.$router.push({ name : 'pending-orders' });
          }).finally(()=>this.$utils.hideLoading());
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  background: #1e88e5;
  color: white;
  font-weight: 600;
  display: block;
  height: 45px;
  flex: 1;
}

.submit-btns {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.card {
  padding-left: 10px;
  padding-right: 10px;
  max-width: 400px;
  width: 95vw;
  position: relative;
}
</style>
