import Vue from 'vue'
import VueRouter from 'vue-router'
import UserLoginView from "@/views/UserLoginView";
import Layout from "@/components/layout/Layout";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'userLogin',
            component: UserLoginView
        },

        {
            path: '/',
            component: Layout,
            children : [
                {
                    path: '/pending-orders',
                    name: 'pending-orders',
                    component: () => import(/* webpackChunkName: "pending-orders" */ '../views/PendingOrdersView')
                },
                {
                    path: '/view-order',
                    name: 'view-order',
                    component: () => import(/* webpackChunkName: "view-order" */ '../views/ViewOrder')
                },
            ],
        },
    ]
})

export default router
